import PropTypes from 'prop-types'
import clsx from 'clsx'
import upperFirst from 'lodash/upperFirst'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { VerticalAlign } from '../../components/vertical-align/VerticalAlign'
import { ScmProviders } from '../../constants/ScmProviders'
import { GithubAuthorize } from '../../components/oauth/GithubAuthorize'
import { ScmProviderDetails } from './ScmProviderDetails'

const useStyles = makeStyles(({ palette, spacing }) => ({
  cardConnection: (scmProvider) => ({
    border: `1px solid ${scmProvider?.hasAccess ? palette.success.main : palette.error.main}`,
  }),
  chipConnected: (scmProvider) => ({
    color: palette.common.white,
    backgroundColor: scmProvider?.hasAccess ? palette.success.main : palette.error.main,
    margin: spacing(1),
  }),
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: spacing(1),
    },
  },
  buttonConnected: {
    justifyContent: 'flex-end',
  },
  buttonNotConnected: {
    justifyContent: 'center',
  },
}))

export const ScmProviderCard = (props) => {
  const { scmProvider, provider } = props
  const classes = useStyles(scmProvider || {})

  return (
    <Card variant="outlined" className={clsx({ [classes.cardConnection]: Boolean(scmProvider) })}>
      <CardHeader
        title={
          <Typography variant="h5" gutterBottom className={classes.title}>
            {ScmProviders[provider].icon} {ScmProviders[provider].label}
          </Typography>
        }
        subheader={
          scmProvider &&
          ScmProviders[provider].revokeUrl && (
            <Typography
              variant="caption"
              component="a"
              href={ScmProviders[provider].revokeUrl}
              target="_blank"
            >
              <VerticalAlign>
                Manage Access on {upperFirst(provider)}
                <OpenInNewIcon fontSize="inherit" />
              </VerticalAlign>
            </Typography>
          )
        }
        action={
          scmProvider && (
            <Chip
              variant="default"
              size="small"
              label={scmProvider.hasAccess ? 'Authorized' : 'Connection Error - Reauthorize'}
              className={classes.chipConnected}
            />
          )
        }
      />
      <CardContent>
        <Grid container alignItems="center">
          {scmProvider && (
            <Grid item xs={12} sm={8}>
              <ScmProviderDetails {...scmProvider} />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            container
            sm={scmProvider ? 4 : 12}
            className={clsx({
              [classes.buttonConnected]: scmProvider,
              [classes.buttonNotConnected]: !scmProvider,
            })}
          >
            {provider === ScmProviders.github.key && <GithubAuthorize />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

ScmProviderCard.propTypes = {
  provider: PropTypes.oneOf(Object.keys(ScmProviders)).isRequired,
  scmProvider: PropTypes.object,
}

ScmProviderCard.defaultProps = {
  scmProvider: null,
}
