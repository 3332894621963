import { createTheme, lighten } from '@material-ui/core/styles'
import { Colors } from './constants/Colors'

const PRIMARY = '#3DC2F9'
const SECONDARY = '#EE8675'
const EIGENGRAU = '#16161d'
const GREY = lighten(Colors.dark.main, 0.6)

const props = {
  drawer: {
    width: 240,
  },
}

const mixins = {
  toolbar: {
    minHeight: 76,
  },
}

const palette = {
  primary: {
    main: PRIMARY,
    contrastText: '#fff',
  },
  secondary: {
    main: SECONDARY,
  },
  common: {
    black: EIGENGRAU,
  },
  status: {
    queued: GREY,
    scheduled: GREY,
    pending: GREY,
    started: Colors.orange.main,
    building: Colors.orange.main,
    checkout: Colors.orange.main,
    passed: Colors.green.main,
    paused: GREY,
    canceled: GREY,
    cancelled: GREY,
    failed: Colors.red.light,
    default: GREY,
  },
  type: 'light',
}

export const lightTheme = createTheme({
  props,
  mixins,
  palette: {
    ...palette,
    type: 'light',
  },
})

export const darkTheme = createTheme({
  props,
  mixins,
  palette: {
    ...palette,
    type: 'dark',
  },
})
