import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import { VerticalAlign } from '../../components/vertical-align/VerticalAlign'
import { booleanToHuman, formatDate } from '../../utils'

export const ScmProviderDetails = (props) => {
  const { privateReposAllowed, createdAt, lastUsedAt } = props

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Typography variant="caption">Access to Private Repos</Typography>
        <Typography variant="body1">
          <VerticalAlign>
            {privateReposAllowed ? (
              <LockIcon fontSize="inherit" />
            ) : (
              <LockOpenIcon fontSize="inherit" />
            )}
            {booleanToHuman(privateReposAllowed)}
          </VerticalAlign>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="caption">Created At</Typography>
        <Typography variant="body1">{formatDate(createdAt)}</Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="caption">Last Used At</Typography>
        <Typography variant="body1">
          {lastUsedAt === createdAt ? 'Never' : formatDate(lastUsedAt)}
        </Typography>
      </Grid>
    </Grid>
  )
}

ScmProviderDetails.propTypes = {
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  lastUsedAt: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  privateReposAllowed: PropTypes.bool.isRequired,
}
