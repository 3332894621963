import { useCallback, useState } from 'react'
import NewWindow from 'react-new-window'
import upperFirst from 'lodash/upperFirst'
import { appHost } from '../../config'
import { useEventListener } from '../../hooks/useEventListener'
import { useNotifications } from '../../hooks/useNotifications'
import { GithubButton } from '../buttons/GithubButton'
import { useCreateAccessToken } from '../../services/github/github.queries'
import { genRandomString } from '../../utils'
import { githubClientId, githubBaseUrl } from '../../config'

export const getGithubAuthorizationUrl = (state, allowPrivateRepos) => {
  // repo/public_repo give read/write access to hooks as well
  const scope = allowPrivateRepos ? 'repo' : 'public_repo'

  return `${githubBaseUrl}/login/oauth/authorize?client_id=${githubClientId}&state=${state}&scope=${scope}`
}

// To view authorization information:
// https://github.com/settings/connections/applications/:client_id
export const GithubAuthorize = (props) => {
  const [initialState] = useState(genRandomString())
  const [window, setWindow] = useState(null)
  const [isRequestOAuth, setIsRequestOAuth] = useState(false)
  const [allowPrivateRepos, setAllowPrivateRepos] = useState(true)
  const { showSuccess, showError } = useNotifications()

  const authorizeUrl = getGithubAuthorizationUrl(initialState, allowPrivateRepos)

  const { mutateAsync: createAccessToken } = useCreateAccessToken({
    onSuccess: (scm) => showSuccess(`${upperFirst(scm.provider)} successfully connected`),
    onError: ({ message }) => showError(message),
  })

  const handleOauthResult = useCallback(
    async ({ data, origin, isTrusted }) => {
      // ensure message is sent from us
      if (!isTrusted || origin !== appHost) {
        return
      }

      // ensure Github's returned state matches our initial
      const { code, state, provider } = data
      if (!code || !state || state !== initialState) {
        return
      }

      await createAccessToken({ code, provider })
    },
    [initialState],
  )
  useEventListener('message', handleOauthResult)

  const handleOnClick = (e, allowPrivateRepos) => {
    setAllowPrivateRepos(allowPrivateRepos)
    setIsRequestOAuth(true)
    window?.focus()
  }

  const handleOnChange = (e, allowPrivateRepos) => {
    setAllowPrivateRepos(allowPrivateRepos)
    setIsRequestOAuth(false)
    setWindow(null)
    window?.close()
  }

  const handleOnBlock = () => {
    setIsRequestOAuth(false)
    showError('Error opening OAuth window. Ensure no popup blocker is preventing it.')
  }

  const handleOnUnload = () => {
    setIsRequestOAuth(false)
    setWindow(null)
  }

  return (
    <>
      <GithubButton title="Authorize" onClick={handleOnClick} onChange={handleOnChange} />

      {isRequestOAuth && (
        <NewWindow
          url={authorizeUrl}
          copyStyles={false}
          onOpen={setWindow}
          onUnload={handleOnUnload}
          onBlock={handleOnBlock}
          center="parent"
          {...props}
        />
      )}
    </>
  )
}
