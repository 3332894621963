import { useMutation, useQuery } from '@tanstack/react-query'
import { addQueryData, updateQueryData } from '../../libs/react-query'
import { queryIds as scmQueryIds } from '../scm/scm.queries'
import { createAccessToken, listBranches, listRepos } from './github.io'

export const queryIds = {
  useRepos: ({ page, per_page }) => ['scm', 'github', 'repos', { page, per_page }],
  useBranches: (owner, repo, { page, per_page }) => [
    'scm',
    'github',
    owner,
    repo,
    'branches',
    { page, per_page },
  ],
}

export const useRepos = ({ page, per_page }, options = {}) =>
  useQuery(queryIds.useRepos({ page, per_page }), () => listRepos({ page, per_page }), options)

export const useBranches = (owner, repo, { page, per_page } = {}, options = {}) =>
  useQuery(
    queryIds.useBranches(owner, repo, { page, per_page }),
    () => listBranches(owner, repo, { page, per_page }),
    options,
  )

export const useCreateAccessToken = (options = {}) =>
  useMutation(({ code, provider }) => createAccessToken({ code, provider }), {
    ...options,
    onSuccess: (scm) => {
      addQueryData(scmQueryIds.useSCMProviders(), scm)
      updateQueryData(scmQueryIds.useSCMProviders(), scm, 'provider')
      options?.onSuccess?.(scm)
    },
  })
