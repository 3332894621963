const env = process.env.REACT_APP_ENVIRONMENT || 'local'
const cfg = require(`./${env}.json`)

if (env !== 'prod') {
  console.log('Environment:', env)
}

export const environment = cfg.environment
export const version = process.env.REACT_VERSION
export const appName = 'Unreal Cloud'
export const appHost = cfg.appHost
export const apiHost = cfg.apiHost
export const stripePublishKey = cfg.stripePublishKey
export const githubClientId = cfg.githubClientId
export const githubBaseUrl = 'https://github.com'
export const firebaseConfig = cfg.firebaseConfig
export const pusherConfig = cfg.pusherConfig
export const sentryDsn = cfg.sentryDsn
