import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { makeStyles, darken } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import GithubIcon from '@material-ui/icons/GitHub'

const GITHUB_COLOR = '#26292E'

const useStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    color: palette.common.white,
    backgroundColor: GITHUB_COLOR,
    '&:hover': {
      color: palette.common.white,
      backgroundColor: darken(GITHUB_COLOR, 1),
    },
  },
  icon: {
    marginRight: spacing(1),
  },
  checkbox: {
    // white event when checked
    color: `${palette.common.white} !important`,
  },
}))

export const GithubButton = (props) => {
  const { title, onClick, onChange, ...rest } = props

  const anchorRef = useRef()
  const classes = useStyles()
  const [allowPrivateRepos, setAllowPrivateRepos] = useState(true)

  const handleOnChange = (e, allowPrivateRepos) => {
    setAllowPrivateRepos(allowPrivateRepos)
    onChange(e, allowPrivateRepos)
  }

  return (
    <ButtonGroup variant="contained" ref={anchorRef} {...rest}>
      <Button className={classes.button} onClick={(e) => onClick(e, allowPrivateRepos)}>
        <GithubIcon className={classes.icon} />
        {title}
      </Button>
      <Button className={classes.button} size="small">
        <Tooltip title="Allow Private Repos Access" placement="top">
          <Checkbox
            size="small"
            className={classes.checkbox}
            checked={allowPrivateRepos}
            onChange={(e) => handleOnChange(e, e.target.checked)}
          />
        </Tooltip>
      </Button>
    </ButtonGroup>
  )
}

GithubButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
}

GithubButton.defaultProps = {
  title: 'Connect with Github',
}
